import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openMap } from '@/store/actions/clubsFilter';
import { useRouter } from 'next/router';
import Image from 'next/image';

import LocationFilter from './filters/LocationFilter';
import SportFilter from './filters/SportFilter';
import DateFilter from './filters/DateFilter';
import HourFilter from './filters/HourFilter';
import RadiusFilter from './filters/RadiusFilter';
import dayjs from 'dayjs';
import AmenitiesFilter from "@/pages/clubs/components/filters/AmenitiesFilter";

const ClubFilters: React.FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;
  const [filters, setFilters] = useState(false);
  const [radius, setRadius] = useState(false);
  const { date, hour } = query;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRadius(!!query.radius);
    }
  }, [query.radius]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const body = document.querySelector('body');
      if (body) {
        filters ? body.classList.add('fixed') : body.classList.remove('fixed');
      }
    }
  }, [filters]);

  const handleOpenMap = useCallback(() => {
    dispatch(openMap());
  }, [dispatch]);

  const toggleFilters = useCallback(() => {
    setFilters(prevFilters => !prevFilters);
  }, []);

  return (
      <div className='d-flex w-100'>
        <div className="filters-bar">
          <div>{date ? dayjs(date as string).format('DD.MM.YYYY') : ''}</div>
          <div>{hour}</div>
          <div className="open-map" onClick={handleOpenMap}>
            <Image className="img-fluid" src='/images/icon_show_map.jpg' width={20} height={20} alt="map" />
          </div>
          <div className="open-filters" onClick={toggleFilters}>
            <Image className="img-fluid" src='/images/icon_filters.png' width={20} height={20} alt="filters" />
          </div>
        </div>
        <div className={`filters ${filters ? 'active' : ''}`}>
          <div className="m-0">
            <div className="close" onClick={toggleFilters}>
              <span className="material-icons">close</span>
            </div>
            <div className="filters-wrapper">
              <div className="row align-items-center py-4 p-md-0">
                <div className={`col-sm-6 mb-lg-0 mb-3 ${radius ? 'col-lg-2' : 'col-lg-3'}`}>
                  <LocationFilter />
                </div>
                {radius && (
                    <div className="col-sm-6 col-lg-2 mb-md-0 mb-3">
                      <RadiusFilter />
                    </div>
                )}
                <div className={`col-sm-6 mb-lg-0 mb-3 ${radius ? 'col-lg-2' : 'col-lg-2'}`}>
                  <SportFilter />
                </div>
                <div className="col-sm-6 col-lg-2 mb-lg-0 mb-3">
                  <DateFilter />
                </div>
                <div className={`col-sm-6 mb-lg-0 mb-3 ${radius ? 'col-lg-2' : 'col-lg-2'}`}>
                  <HourFilter />
                </div>
                <div className={`col-sm-6 mb-lg-0 mb-3 ${radius ? 'col-lg-2' : 'col-lg-3'}`}>
                  <AmenitiesFilter />
                </div>
              </div>
              <div className="row d-lg-none">
                <div className="col-12 text-center pb-3">
                  <button onClick={toggleFilters} className="btn btn-success w-100 mt-3">Cauta</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default React.memo(ClubFilters);
