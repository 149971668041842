'use client'
import React, { useState, useEffect, useRef } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useApi } from "@/hooks/useApi";
import InputLabel from "@mui/material/InputLabel";

// Styled component
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '40px',
        display: 'inline-block',
        width: '100%',
        textAlign: 'left'
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

interface Option {
    label: string;
    value: string | number;
}

interface QueryParams {
    location?: string;
    radius?: string;
    [key: string]: any;
}

const removeCurrentLocationEntries = (list: Option[]): Option[] => {
    return list.filter((city) => {
        if (city.label === 'LOCAȚIA CURENTĂ') return false;
        if (city.value === 0) return false;
        if (typeof city.value === 'string' && city.value.startsWith('@')) return false;
        return true;
    });
};

const LocationFilter: React.FC = () => {
    const [locationsOptions, setLocationsOptions] = useState<Option[]>([]);
    const [locationValue, setLocationValue] = useState<string | number>('');
    const [loading, setLoading] = useState<boolean>(true);
    const router = useRouter();
    const { pathname } = router;
    const currentParams: QueryParams = typeof window !== 'undefined' ? queryString.parse(location.search) as QueryParams : {};
    const locationValueRef = useRef(locationValue);
    const booksport = useApi();

    useEffect(() => {
        locationValueRef.current = locationValue;
    }, [locationValue]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const res = await booksport.get('/get_cities');
                const cities: Option[] = res.data.map((city: { name: string; id: string }) => ({
                    label: city.name,
                    value: city.id,
                }));

                const permission = await navigator.permissions.query({ name: 'geolocation' });
                let currentLocation: Option | null = null;

                if (permission.state === 'granted') {
                    currentLocation = await new Promise((resolve) => {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                resolve({
                                    label: 'LOCAȚIA CURENTĂ',
                                    value: `@${position.coords.latitude},${position.coords.longitude}`,
                                });
                            },
                            () => resolve(null)
                        );
                    });
                } else {
                    currentLocation = { label: 'LOCAȚIA CURENTĂ', value: 0 };
                }
                if (currentLocation) {
                    cities.unshift(currentLocation);
                }
                setLocationsOptions(cities);

                if (
                    currentParams.location?.toString().startsWith('@') &&
                    currentLocation?.value === currentParams.location?.toString()
                ) {
                    setLocationValue(currentLocation?.value || '');
                } else if (cities.some((city) => city.value.toString() === currentParams.location?.toString())) {
                    setLocationValue(currentParams.location?.toString() || '');
                } else {
                    setLocationValue('');
                }
            } catch (err: any) {
                console.error(err.response);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchCities().catch();
    }, []);

    useEffect(() => {
        if (currentParams.location?.toString().startsWith('@')) {
            if (!currentParams.radius) {
                currentParams.radius = '10';
            }
        } else {
            delete currentParams.radius;
        }
        const newQueryString = queryString.stringify(currentParams);
        router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
    }, [router.pathname]);

    useEffect(() => {
        let permissionStatus: PermissionStatus | null = null;
        navigator.permissions.query({ name: 'geolocation' })
            .then(status => {
                permissionStatus = status;
                status.onchange = () => {
                    if (status.state === 'denied') {

                        if (typeof locationValueRef.current === 'string' && locationValueRef.current.startsWith('@')) {
                            setLocationsOptions(prevOptions => {
                                const filtered = removeCurrentLocationEntries(prevOptions);
                                const updatedOptions = [{ label: 'LOCAȚIA CURENTĂ', value: 0 }, ...filtered];
                                const firstValid = updatedOptions.slice(1).find(opt =>
                                    opt.value !== 0 && !(typeof opt.value === 'string' && opt.value.startsWith('@'))
                                );
                                const newLocationValue = firstValid ? firstValid.value : '';
                                setLocationValue(newLocationValue);

                                const updatedParams = { ...currentParams, location: newLocationValue.toString() };
                                delete updatedParams.radius;
                                const newQueryString = queryString.stringify(updatedParams);
                                router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
                                return updatedOptions;
                            });
                        }
                    } else if (status.state === 'granted') {
                        if (locationValueRef.current === 0 || locationValueRef.current === '0') {
                            navigator.geolocation.getCurrentPosition(
                                (position) => {
                                    const newLocation = {
                                        label: 'LOCAȚIA CURENTĂ',
                                        value: `@${position.coords.latitude},${position.coords.longitude}`,
                                    };
                                    setLocationsOptions(prevOptions => {
                                        const filtered = removeCurrentLocationEntries(prevOptions);
                                        const updatedOptions = [newLocation, ...filtered];
                                        return updatedOptions;
                                    });
                                    setLocationValue(newLocation.value);
                                    const updatedParams = { ...currentParams, location: newLocation.value.toString(), radius: '10' };
                                    const newQueryString = queryString.stringify(updatedParams);
                                    router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
                                },
                                (error) => {
                                    console.error("Error fetching geolocation:", error);
                                }
                            );
                        }
                    }
                };
            })
            .catch(err => console.error("Permission query error:", err));

        return () => {
            if (permissionStatus) {
                permissionStatus.onchange = null;
            }
        };
    }, [router, pathname, currentParams]);

    const handleLocationChange = (event: any) => {
        const value = event.target.value as string | number;

        if (value === 0) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const newLocation: Option = {
                        label: 'LOCAȚIA CURENTĂ',
                        value: `@${position.coords.latitude},${position.coords.longitude}`,
                    };

                    setLocationsOptions((prev) => {
                        const filtered = removeCurrentLocationEntries(prev);
                        filtered.unshift(newLocation);
                        return filtered;
                    });
                    setLocationValue(newLocation.value);

                    currentParams.location = newLocation.value.toString();
                    if (newLocation.value.toString().startsWith('@')) {
                        if (!currentParams.radius) {
                            currentParams.radius = '10';
                        }
                    } else {
                        delete currentParams.radius;
                    }
                    const newQueryString = queryString.stringify(currentParams);
                    router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        toast.error(
                            'Ai blocat accesul la locație. Activează-l din setările browserului pentru a folosi această opțiune.'
                        );
                    }
                }
            );
            return;
        } else {
            const selected = locationsOptions.find((option) => option.value === value);
            if (selected) {
                setLocationValue(selected.value);
            }
            currentParams.location = value.toString();
            if (value.toString().startsWith('@')) {
                if (!currentParams.radius) {
                    currentParams.radius = '10';
                }
            } else {
                delete currentParams.radius;
            }
            const newQueryString = queryString.stringify(currentParams);
            router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
            setLocationValue(value.toString());
        }
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="locatii" className='hidden'>Locatii</InputLabel>
            <GreenSelect
                value={locationValue}
                onChange={handleLocationChange}
                displayEmpty
                labelId="locatii"
                renderValue={(selected: unknown): React.ReactNode => {
                    const value = selected?.toString() || '';
                    if (value === '') {
                        return <span>Selectează locația</span>;
                    }
                    const selectedOption = locationsOptions.find(
                        (option) => option.value.toString() === value.toString()
                    );
                    return selectedOption ? selectedOption.label : value;
                }}
                sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {loading ? (
                    <MenuItem disabled value="" sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                        Se încarcă locațiile...
                    </MenuItem>
                ) : (
                    locationsOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                            {option.label}
                        </MenuItem>
                    ))
                )}
            </GreenSelect>

        </FormControl>
    );
};

export default LocationFilter;
