import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ro';
import queryString from 'query-string';
import InputLabel from "@mui/material/InputLabel";

// Extend dayjs with custom parse format
dayjs.extend(customParseFormat);
dayjs.locale('ro'); // Set dayjs to use the Romanian locale

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const HourFilter = () => {
    const [hoursOptions, setHoursOptions] = useState([]);
    const [selectedHour, setSelectedHour] = useState('');
    const router = useRouter();
    const { query, pathname } = router;
    const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};

    useEffect(() => {
        const generateHours = (selectedDate) => {
            const now = dayjs();
            const isToday = selectedDate === now.format('YYYY-MM-DD');
            const hours = [];

            for (let i = 0; i < 24; i++) {
                const hourStr = i.toString().padStart(2, '0') + ':00';
                const halfHourStr = `${hourStr.slice(0, 2)}:30`;

                if (isToday) {
                    if (dayjs(hourStr, 'HH:mm').isAfter(now)) {
                        hours.push({ label: hourStr, value: hourStr });
                    }
                    if (dayjs(halfHourStr, 'HH:mm').isAfter(now)) {
                        hours.push({ label: halfHourStr, value: halfHourStr });
                    }
                } else {
                    if (i >= 7 && i < 24) {
                        hours.push({ label: hourStr, value: hourStr });
                        hours.push({ label: halfHourStr, value: halfHourStr });
                    }
                }
            }
            return hours;
        };

        const selectedDate = Array.isArray(queryParams.date) ? queryParams.date[0] : queryParams.date || dayjs().format('YYYY-MM-DD');
        const currentHour = dayjs().format('HH:mm');
        let selectedHour = Array.isArray(queryParams.hour) ? queryParams.hour[0] : queryParams.hour;

        const hours = generateHours(selectedDate);
        setHoursOptions(hours);

        const isValidHour = (hour) => {
            return hours.some(h => h.value === hour);
        };

        if (!selectedHour || !isValidHour(selectedHour) || (selectedDate === dayjs().format('YYYY-MM-DD') && selectedHour < currentHour)) {
            const nextClosestHour = hours.find(hour => hour.value > currentHour) || hours[0];
            selectedHour = nextClosestHour.value;
            queryParams.hour = selectedHour;
            router.replace(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
        }

        setSelectedHour(selectedHour);
    }, [queryParams.date, queryParams.hour, router, pathname]);

    const handleHourChange = (event) => {
        const newHour = event.target.value;
        const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
        queryParams.hour = newHour;
        router.push(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
        setSelectedHour(newHour);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="ora" className='hidden'>Ora</InputLabel>
            <GreenSelect
                value={selectedHour}
                onChange={handleHourChange}
                displayEmpty
                labelId="ora"
                sx={{fontFamily: 'Poppins, Arial, sans-serif'}}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                { !hoursOptions.length ? (
                    <MenuItem disabled value="" sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                        Se încarcă orele...
                    </MenuItem>
                    ) : ( hoursOptions.map(option => (
                    <MenuItem key={option.value} value={option.value} sx={{fontFamily: 'Poppins, Arial, sans-serif'}}>
                        {option.label}
                    </MenuItem>
                )))}
            </GreenSelect>
        </FormControl>
    );
};

export default HourFilter;
