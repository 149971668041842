import React, { useEffect, useState, useCallback } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ro';
import queryString from 'query-string';

// Extend dayjs with custom parse format
dayjs.extend(customParseFormat);
dayjs.locale('ro'); // Set dayjs to use the Romanian locale

const GreenTextField = styled(TextField)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const DateFilter = () => {
    const router = useRouter();
    const { query, pathname } = router;
    const [startDate, setStartDate] = useState(dayjs());

    const updateQueryParams = useCallback((date) => {
        const newDate = date.format('YYYY-MM-DD');
        const currentParams = queryString.parse(location.search);
        const newParams = { ...currentParams, date: newDate };
        const newQueryString = queryString.stringify(newParams);
        router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
    }, [router, pathname]);

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const selectedDate = queryParams.date ? dayjs(queryParams.date, 'YYYY-MM-DD', true) : null;
        const currentDate = dayjs();
        const minDate = currentDate;
        const maxDate = currentDate.add(14, 'day');

        if (selectedDate && (!selectedDate.isValid() || selectedDate.isBefore(minDate, 'day') || selectedDate.isAfter(maxDate, 'day'))) {
            setStartDate(currentDate);
            updateQueryParams(currentDate);
        } else if (!queryParams.date) {
            setStartDate(currentDate);
            updateQueryParams(currentDate);
        } else if (!selectedDate.isSame(startDate, 'day')) {
            setStartDate(selectedDate);
        }
    }, [query, startDate, updateQueryParams]);

    const handleDateChange = useCallback((date) => {
        if (date.isValid() && !date.isBefore(dayjs().subtract(1, 'day')) && !date.isAfter(dayjs().add(14, 'day'))) {
            setStartDate(date);
            updateQueryParams(date);
        }
    }, [updateQueryParams]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
            <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
                <DatePicker
                    value={startDate}
                    onChange={handleDateChange}
                    minDate={dayjs()}
                    maxDate={dayjs().add(14, 'day')}
                    textField={(params) => (
                        <GreenTextField
                            sx={{fontFamily: 'Poppins, Arial, sans-serif'}}
                            {...params}
                            fullWidth
                            label="Data"
                            variant="outlined"
                            inputProps={{ ...params.inputProps, readOnly: true }} // Make input read-only
                        />
                    )}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            'aria-label': 'data',
                            sx: {
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "green"
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "green"
                                },
                                "& .MuiInputBase-root": {
                                    height: '40px',
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: '40px',
                                },
                                margin: 0
                            }
                        }
                    }}
                    format="DD.MM.YYYY"
                />
            </Box>
        </LocalizationProvider>
    );
};

export default React.memo(DateFilter);
