'use client'
import React, { useState, useEffect, useCallback } from 'react';
import { FormControl, MenuItem, Select, Box, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import { PulseLoader } from 'react-spinners';
import queryString from 'query-string';
import InputLabel from "@mui/material/InputLabel";

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const RadiusFilter: React.FC = () => {
    const router = useRouter();
    const { query, pathname } = router;
    const [radius, setRadius] = useState<string>('');

    const radiusOptions = [
        { label: '5 km', value: '5' },
        { label: '10 km', value: '10' },
        { label: '20 km', value: '20' },
        { label: '50 km', value: '50' },
    ];

    useEffect(() => {
        const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
        const { location: locationValue, radius: queryRadius } = queryParams;

        if (locationValue && typeof locationValue === 'string' && locationValue.startsWith('@')) {
            if (!queryRadius) {
                queryParams.radius = '10';
                setRadius('10');
                router.replace(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
            } else {
                setRadius(queryRadius as string);
            }
        } else {
            if (queryRadius) {
                delete queryParams.radius;
                router.replace(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
            }
            setRadius('');
        }
    }, [router, pathname]);

    const handleRadiusChange = useCallback((event: any) => {
        const newRadius = event.target.value;
        const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
        if (newRadius) {
            queryParams.radius = newRadius;
        } else {
            delete queryParams.radius;
        }
        router.push(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
        setRadius(newRadius);
    }, [router, pathname]);

    return (
        <FormControl fullWidth>
            <InputLabel id="raza" className='hidden'>Raza</InputLabel>
            <GreenSelect
                value={radius}
                onChange={handleRadiusChange}
                displayEmpty
                labelId="raza"
                sx={{fontFamily: 'Poppins, Arial, sans-serif'}}
                aria-label="Radius Filter"
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {radiusOptions.map(option => (
                    <MenuItem key={option.value} value={option.value} sx={{fontFamily: 'Poppins, Arial, sans-serif'}}>
                        {option.label}
                    </MenuItem>
                ))}
            </GreenSelect>
        </FormControl>
    );
};

export default RadiusFilter;
