import { useRef } from 'react';
import axios, {
    AxiosInstance,
    AxiosError,
    InternalAxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '@/context/AuthContext';

type CustomConfig = InternalAxiosRequestConfig & {
    usetoken?: boolean;
};

let isRedirecting = false;

export function useApi(): AxiosInstance {
    const { token, setToken, getOrFetchToken } = useAuth();
    const instanceRef = useRef<AxiosInstance | null>(null);
    const {logout} = useAuth()

    if (!instanceRef.current) {
        const instance = axios.create({
            baseURL: process.env.NEXT_PUBLIC_API_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // REQUEST INTERCEPTOR
        instance.interceptors.request.use(
            async (config: CustomConfig) => {
                if (config.usetoken) {
                    let currentToken = token;
                    if (!currentToken) {
                        currentToken = await getOrFetchToken();
                    }

                    if (currentToken) {
                        config.params = { ...config.params, token: currentToken };
                        if (config.method?.toUpperCase() !== 'GET' && config.method?.toUpperCase() !== 'DELETE') {
                            config.headers = config.headers || {};
                            config.headers['Authorization'] = `Bearer ${currentToken}`;
                        }
                    } else {
                        console.warn('[useApi] Token not available.');
                    }
                }

                // Remove `usetoken` before passing config to Axios
                const { usetoken, ...axiosConfig } = config;
                return axiosConfig;
            },
            (error: AxiosError) => {
                console.error('[Request Error]', error);
                return Promise.reject(error);
            }
        );

        // RESPONSE INTERCEPTOR
        instance.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error: AxiosError) => {
                if (
                    error.response?.status === 401 &&
                    typeof window !== 'undefined' &&
                    !isRedirecting
                ) {
                    isRedirecting = true;
                    setToken(null);
                    await logout()

                    toast.error('Session expired. Redirecting to login...', {
                        onClose: () => {
                            window.location.href = '/login';
                        },
                    });
                    return Promise.resolve();
                }
                console.error('[Response Error]', error);
                return Promise.reject(error);
            }
        );

        instanceRef.current = instance;
    }

    return instanceRef.current;
}
