import React, { useState, useEffect } from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    Button,
    Box
} from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useApi } from '@/hooks/useApi';
import { CustomCheckbox } from '@/components/form-elements/CustomCheckbox';
import InputLabel from "@mui/material/InputLabel";

interface AmenityOption {
    label: string;
    value: string;
}

const PoppinsListItemText = styled(ListItemText)({
    '& .MuiTypography-root': {
        fontFamily: 'Poppins, Arial, sans-serif'
    }
});

const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green'
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center'
    },
    '.MuiSelect-select': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '40px',
        display: 'inline-block',
        width: '100%',
        textAlign: 'left'
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center'
    }
});

const AmenitiesFilter: React.FC = () => {
    const [amenities, setAmenities] = useState<AmenityOption[]>([]);
    const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const router = useRouter();
    const { pathname } = router;
    const queryParams =
        typeof window !== 'undefined'
            ? queryString.parse(window.location.search)
            : {};

    const booksport = useApi();

    useEffect(() => {
        const sport = queryParams.sport as string;
        if (sport) {
            setLoading(true);
            const fetchAmenities = async () => {
                try {
                    const res = await booksport.get('/getamenities', {
                        params: { sport_id: sport }
                    });

                    const fetchedAmenities = res.data.map(
                        (amenity: { label: string; id: number }) => ({
                            label: amenity.label,
                            value: amenity.id.toString()
                        })
                    );

                    setAmenities(fetchedAmenities);

                    if (queryParams.amenities) {
                        const existingValues = (queryParams.amenities as string).split(',');
                        const validValues = existingValues.filter((val) =>
                            fetchedAmenities.some((a:any) => a.value === val)
                        );
                        setSelectedAmenities(validValues);
                    }

                    setLoading(false);
                } catch (err: any) {
                    console.error(err.response?.data || err);
                    setLoading(false);
                }
            };

            fetchAmenities().catch(() => setLoading(false));
        }
    }, [queryParams.sport]);

    const handleToggleAmenity = (value: string) => {
        setSelectedAmenities((prev) =>
            prev.includes(value)
                ? prev.filter((val) => val !== value)
                : [...prev, value]
        );
    };

    const applyFilters = async () => {
        const currentParams = { ...queryParams };

        if (selectedAmenities.length > 0) {
            currentParams.amenities = selectedAmenities.join(',');
        } else {
            delete currentParams.amenities;
        }

        const newQueryString = queryString.stringify(currentParams);

        await router.replace(`${pathname}?${newQueryString}`, undefined, {
            shallow: true
        });
        setOpen(false);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="facilitati" className='hidden'>Facilitati</InputLabel>
            <GreenSelect
                multiple
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={selectedAmenities}
                displayEmpty
                labelId="facilitati"
                renderValue={(selected) => {
                    if ((selected as string[]).length === 0) {
                        return (
                            <span style={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                               Selectează facilități
                            </span>
                                        );
                                    }
                                    const text = (selected as string[])
                                        .map(
                                            (val) =>
                                                amenities.find((a) => a.value === val)?.label || val
                                        )
                                        .join(', ');
                                    return (
                                <span style={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                            {text}
                        </span>
                    );
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            width: 250,
                            height: 400,
                            backgroundColor: '#fff',
                            paddingBottom: 0,
                        }
                    },
                    MenuListProps: {
                        disablePadding: true
                    },
                    disableScrollLock: true,
                }}
            >
                <Box style={{ flex: 1, paddingBottom: 0 }}>
                    {loading ? (
                        <MenuItem disabled value="" sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                            Se încarcă facilități...
                        </MenuItem>
                    ) : (
                        <>
                            {selectedAmenities.length > 1 && (
                                <MenuItem
                                    key="deselect_all"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedAmenities([]);
                                    }}
                                    sx={{
                                        paddingX: 0,
                                        paddingY: '5px',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5'
                                        },
                                        fontFamily: 'Poppins, Arial, sans-serif'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            color: '#12803c',
                                            fontWeight: '500',
                                            py: 1,
                                            fontFamily: 'Poppins, Arial, sans-serif'
                                        }}
                                    >
                                        Deselectează toate
                                    </Box>
                                </MenuItem>
                            )}
                            {amenities.map((option) => {
                                const isChecked = selectedAmenities.includes(option.value);
                                return (
                                    <MenuItem
                                        key={option.value}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleToggleAmenity(option.value);
                                        }}
                                        selected={isChecked}
                                        sx={{
                                            paddingX: 0,
                                            paddingY: '5px',
                                            fontFamily: 'Poppins, Arial, sans-serif'
                                        }}
                                    >
                                        <CustomCheckbox
                                            checked={isChecked}
                                            style={{ padding: '0 10px' }}
                                        />
                                        <PoppinsListItemText primary={option.label} />
                                    </MenuItem>
                                );
                            })}
                        </>
                    )}
                </Box>

                <Box sx={{ p: 1, borderTop: '1px solid #ddd', backgroundColor: '#fff', position: 'sticky', bottom:0}}>
                    <Button
                        variant="contained"
                        style={{ background: '#12803c', color: '#fff' }}
                        onClick={applyFilters}
                        disabled={loading}
                        fullWidth
                    >
                        Aplică
                    </Button>
                </Box>
            </GreenSelect>
        </FormControl>
    );
};

export default AmenitiesFilter;
